<template>
<!-- 居中标题 -->
<div class="newtitle">
  <div></div>
  <div><font v-if="rules" style="color:#EE1C1C;">*</font>{{name}}</div>
  <div></div>
</div>
</template>
<script>
export default {
  name: 'titleCenter',
  props: {
    name: String,
    rules: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" src='../index.scss' scoped />
