var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog',{staticClass:"sealOrder",attrs:{"visible":_vm.currentVisible,"showFooter":false,"title":"查看"},on:{"update:visible":function($event){_vm.currentVisible=$event}}},[_c('div',[_c('div',{staticClass:"tableBox"},[_c('title-center',{staticClass:"tableTitle",attrs:{"name":"盖章序文件"}}),_c('base-table',{attrs:{"columns":_vm.orderColumns,"showPage":false,"tableAttrs":{
          spanMethod: _vm.objectSpanMethod,
          data: _vm.orderData,
          stripe: false,
          border: true,
          height:'320'
        },"dataSource":_vm.orderData,"is-caculate-height":false},on:{"update:dataSource":function($event){_vm.orderData=$event},"update:data-source":function($event){_vm.orderData=$event}}})],1),_c('div',{staticClass:"tableBox"},[_c('title-center',{staticClass:"tableTitle",attrs:{"name":"无盖章序文件"}}),_c('base-table',{attrs:{"columns":_vm.disorderColumns,"showPage":false,"tableAttrs":{
          spanMethod: _vm.objectSpanMethod,
          tableData: _vm.disorderData,
          stripe: false,
          border: true,
          height:'320'
        },"dataSource":_vm.disorderData,"is-caculate-height":false},on:{"update:dataSource":function($event){_vm.disorderData=$event},"update:data-source":function($event){_vm.disorderData=$event}}})],1)]),_c('template',{slot:"footer"},[_c('base-button',{attrs:{"label":"关 闭","type":"default"},on:{"click":function($event){_vm.currentVisible = false}}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }