import { formatDate } from '@/utils/auth/common'

// 有序列表
export const orderData = (context) => {
  return [
    {
      label: '盖章序',
      prop: 'seq',
      width: '80px'
    },
    {
      label: '文件类型',
      prop: 'docName',
      'show-overflow-tooltip': true,
      'min-width': '150px',
      align: 'left'
    },
    {
      label: '文件名称',
      prop: 'fileName',
      'show-overflow-tooltip': true,
      'min-width': '200px',
      align: 'left'
    },
    {
      label: '盖章主体',
      prop: 'signName',
      'min-width': '100px',
      align: 'left'
    }
  ]
}
// 无序列表
export const disorderData = (context) => {
  return [
    {
      label: '文件名称',
      prop: 'docName',
      'show-overflow-tooltip': true,
      align: 'left'
    },
    {
      label: '盖章主体',
      prop: 'signName',
      align: 'left'

    }
  ]
}

// 文件信息
export const infoData = (context) => {
  return [
    {
      label: '序号',
      prop: 'code'
    },
    {
      label: '文件名称',
      prop: 'fileName',
      'show-overflow-tooltip': true,
      align: 'left'
    },
    {
      label: '盖章主体',
      prop: ''
    }
  ]
}
// 文件信息
export const signCheckConfig = (context) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '60'
    },
    {
      label: '证书序列号',
      prop: 'certSerialNumber',
      'show-overflow-tooltip': true,
      width: '120'
    },
    {
      label: '证书颁发者',
      prop: 'certIssuer',
      align: 'left',
      width: 150

    },
    {
      label: '证书持有人信息',
      prop: 'certHolderInfo',
      align: 'left'

    },
    {
      label: '证书有效期',
      prop: 'certValid',
      // formatter: row => {
      // return (row.certValidStart && row.certValidEnd ? formatDate(row.certValidStart, 'YY/MM/DD hh:mm:ss') + ' 至 ' + formatDate(row.certValidEnd, 'YY/MM/DD hh:mm:ss') : '--')
      // },
      // align: 'left',
      HiddenOverflow: true,
      with: 120
    },
    {
      label: '证书有效性',
      prop: 'validFlag',
      width: '90',
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '签名时间',
      prop: 'signDatetime',
      formatter: row => {
        return (row.signDatetime ? formatDate(row.signDatetime, 'YY/MM/DD hh:mm:ss') : '--')
      },
      width: 150
    }
  ]
}
