// 文件盖章序弹窗
<template>
  <base-dialog
    :visible.sync="currentVisible"
    :showFooter="false"
    title="查看"
    class="sealOrder"
  >
    <div>
      <!-- 有序文件 -->
      <div class="tableBox">
        <title-center name="盖章序文件" class="tableTitle"></title-center>
        <base-table
          :columns="orderColumns"
          :showPage="false"
          :tableAttrs="{
            spanMethod: objectSpanMethod,
            data: orderData,
            stripe: false,
            border: true,
            height:'320'
          }"
          :dataSource.sync="orderData"
          :is-caculate-height="false"
        ></base-table>
      </div>
      <!-- 无序文件 -->
      <div class="tableBox">
        <title-center name="无盖章序文件" class="tableTitle"></title-center>
        <base-table
          :columns="disorderColumns"
          :showPage="false"
          :tableAttrs="{
            spanMethod: objectSpanMethod,
            tableData: disorderData,
            stripe: false,
            border: true,
            height:'320'
          }"
          :dataSource.sync="disorderData"

          :is-caculate-height="false"
        ></base-table>
      </div>
    </div>
    <template slot="footer">
      <base-button
        label="关 闭"
        type="default"
        @click="currentVisible = false"
      ></base-button>
    </template>
  </base-dialog>
</template>
<script>
import baseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import TitleCenter from './title-center.vue'
import { orderData, disorderData } from '../utils/seal-datails-config'
export default {
  components: { baseDialog, BaseButton, BaseTable, TitleCenter },
  name: 'detailDialog',
  props: {
    count: Number,
    formInfoconfig: Array,
    detailInfo: Object,
    serlOrderVisible: Boolean,
    orderData: Array,
    disorderData: Array
  },
  data () {
    return {}
  },
  computed: {
    currentVisible: {
      get () {
        return this.serlOrderVisible
      },
      set (val) {
        this.$emit('update:serlOrderVisible', val)
      }
    },
    orderColumns () {
      return orderData(this)
    },
    disorderColumns () {
      return disorderData(this)
    }
  },
  methods: {
    // 表格合并规则
    objectSpanMethod ({ row, column }) {
      if (this.sealType === '0') {
        // 鲜章无合并
        return []
      }
      if (column.property === 'seq') {
        const _row = row.seqRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (column.property === 'docName') {
        const _row = row.docNameRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.sealOrder {
  .tableBox {
    .tableTitle {
      margin: 20px;
    }
  }
}
/deep/  .el-table__body tr,
 /deep/ .el-table__body td {
    padding: 0;
    height: 40px;
}
</style>
